.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

body {
  line-height: 1.2;
  letter-spacing: 0.15px;
  background-color: #FAFBFC;

}

h1 {
  line-height: 1.2;
  letter-spacing: 0.25px;
  font-weight: 300;
}

h2 {}

h3 {}

h4 {
  line-height: 1.2;
  letter-spacing: -1.5px;
  font-weight: 300;
}

a {
  text-decoration: none;
}


/**
 * @license
 *
 * Font Family: Satoshi
 * Designed by: Deni Anggara
 * URL: https://www.fontshare.com/fonts/satoshi
 * © 2022 Indian Type Foundry
 *
 * Font Styles:
 * Satoshi Variable(Variable font)
 * Satoshi Variable Italic(Variable font)
 * Satoshi Light
 * Satoshi Light Italic
 * Satoshi Regular
 * Satoshi Italic
 * Satoshi Medium
 * Satoshi Medium Italic
 * Satoshi Bold
 * Satoshi Bold Italic
 * Satoshi Black
 * Satoshi Black Italic
 *
*/


/**
* This is a variable font
* You can controll variable axes as shown below:
* font-variation-settings: 'wght' 900.0;
*
* available axes:

* 'wght' (range from 300.0 to 900.0)

*/

@font-face {
  font-family: 'Satoshi-Variable';
  src: url('../public/fonts/Satoshi-Variable.woff2') format('woff2'),
    url('../public/fonts/Satoshi-Variable.woff') format('woff'),
    url('../public/fonts/Satoshi-Variable.ttf') format('truetype');
  font-weight: 300 900;
  font-display: swap;
  font-style: normal;
}


/**
* This is a variable font
* You can controll variable axes as shown below:
* font-variation-settings: 'wght' 900.0;
*
* available axes:

* 'wght' (range from 300.0 to 900.0)

*/

@font-face {
  font-family: 'Satoshi-VariableItalic';
  src: url('../public/fonts/Satoshi-VariableItalic.woff2') format('woff2'),
    url('../public/fonts/Satoshi-VariableItalic.woff') format('woff'),
    url('../public/fonts/Satoshi-VariableItalic.ttf') format('truetype');
  font-weight: 300 900;
  font-display: swap;
  font-style: italic;
}


@font-face {
  font-family: 'Satoshi-Light';
  src: url('../public/fonts/Satoshi-Light.woff2') format('woff2'),
    url('../public/fonts/Satoshi-Light.woff') format('woff'),
    url('../public/fonts/Satoshi-Light.ttf') format('truetype');
  font-weight: 300;
  font-display: swap;
  font-style: normal;
}


@font-face {
  font-family: 'Satoshi-LightItalic';
  src: url('../public/fonts/Satoshi-LightItalic.woff2') format('woff2'),
    url('../public/fonts/Satoshi-LightItalic.woff') format('woff'),
    url('../public/fonts/Satoshi-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-display: swap;
  font-style: italic;
}


@font-face {
  font-family: 'Satoshi-Regular';
  src: url('../public/fonts/Satoshi-Regular.woff2') format('woff2'),
    url('../public/fonts/Satoshi-Regular.woff') format('woff'),
    url('../public/fonts/Satoshi-Regular.ttf') format('truetype');
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}


@font-face {
  font-family: 'Satoshi-Italic';
  src: url('../public/fonts/Satoshi-Italic.woff2') format('woff2'),
    url('../public/fonts/Satoshi-Italic.woff') format('woff'),
    url('../public/fonts/Satoshi-Italic.ttf') format('truetype');
  font-weight: 400;
  font-display: swap;
  font-style: italic;
}


@font-face {
  font-family: 'Satoshi-Medium';
  src: url('../public/fonts/Satoshi-Medium.woff2') format('woff2'),
    url('../public/fonts/Satoshi-Medium.woff') format('woff'),
    url('../public/fonts/Satoshi-Medium.ttf') format('truetype');
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}


@font-face {
  font-family: 'Satoshi-MediumItalic';
  src: url('../public/fonts/Satoshi-MediumItalic.woff2') format('woff2'),
    url('../public/fonts/Satoshi-MediumItalic.woff') format('woff'),
    url('../public/fonts/Satoshi-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-display: swap;
  font-style: italic;
}


@font-face {
  font-family: 'Satoshi-Bold';
  src: url('../public/fonts/Satoshi-Bold.woff2') format('woff2'),
    url('../public/fonts/Satoshi-Bold.woff') format('woff'),
    url('../public/fonts/Satoshi-Bold.ttf') format('truetype');
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}


@font-face {
  font-family: 'Satoshi-BoldItalic';
  src: url('../public/fonts/Satoshi-BoldItalic.woff2') format('woff2'),
    url('../public/fonts/Satoshi-BoldItalic.woff') format('woff'),
    url('../public/fonts/Satoshi-BoldItalic.ttf') format('truetype');
  font-weight: 700;
  font-display: swap;
  font-style: italic;
}


@font-face {
  font-family: 'Satoshi-Black';
  src: url('../public/fonts/Satoshi-Black.woff2') format('woff2'),
    url('../public/fonts/Satoshi-Black.woff') format('woff'),
    url('../public/fonts/Satoshi-Black.ttf') format('truetype');
  font-weight: 900;
  font-display: swap;
  font-style: normal;
}


@font-face {
  font-family: 'Satoshi-BlackItalic';
  src: url('../public/fonts/Satoshi-BlackItalic.woff2') format('woff2'),
    url('../public/fonts/Satoshi-BlackItalic.woff') format('woff'),
    url('../public/fonts/Satoshi-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-display: swap;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto';
  src: url('../public/fonts/Roboto-BlackItalic.woff2') format('woff2'),
    url('../public/fonts/Roboto-BlackItalic.woff') format('woff'),
    url('../public/fonts/Roboto-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('../public/fonts/Roboto-Italic.woff2') format('woff2'),
    url('../public/fonts/Roboto-Italic.woff') format('woff'),
    url('../public/fonts/Roboto-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('../public/fonts/Roboto-Bold.woff2') format('woff2'),
    url('../public/fonts/Roboto-Bold.woff') format('woff'),
    url('../public/fonts/Roboto-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('../public/fonts/Roboto-Black.woff2') format('woff2'),
    url('../public/fonts/Roboto-Black.woff') format('woff'),
    url('../public/fonts/Roboto-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('../public/fonts/Roboto-BoldItalic.woff2') format('woff2'),
    url('../public/fonts/Roboto-BoldItalic.woff') format('woff'),
    url('../public/fonts/Roboto-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('../public/fonts/Roboto-MediumItalic.woff2') format('woff2'),
    url('../public/fonts/Roboto-MediumItalic.woff') format('woff'),
    url('../public/fonts/Roboto-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('../public/fonts/Roboto-Medium.woff2') format('woff2'),
    url('../public/fonts/Roboto-Medium.woff') format('woff'),
    url('../public/fonts/Roboto-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('../public/fonts/Roboto-Light.woff2') format('woff2'),
    url('../public/fonts/Roboto-Light.woff') format('woff'),
    url('../public/fonts/Roboto-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('../public/fonts/Roboto-LightItalic.woff2') format('woff2'),
    url('../public/fonts/Roboto-LightItalic.woff') format('woff'),
    url('../public/fonts/Roboto-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('../public/fonts/Roboto-Thin.woff2') format('woff2'),
    url('../public/fonts/Roboto-Thin.woff') format('woff'),
    url('../public/fonts/Roboto-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('../public/fonts/Roboto-ThinItalic.woff2') format('woff2'),
    url('../public/fonts/Roboto-ThinItalic.woff') format('woff'),
    url('../public/fonts/Roboto-ThinItalic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('../public/fonts/Roboto-Regular.woff2') format('woff2'),
    url('../public/fonts/Roboto-Regular.woff') format('woff'),
    url('../public/fonts/Roboto-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

body {
  font-family: Satoshi-Medium, Roboto, sans-serif;
}